<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="名称"></el-table-column>
        <el-table-column align="right" label="操作">
          <template #header>
            <el-button @click="editBox=true" size="mini" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="edit(s.row)" size="mini" type="primary">编辑</el-button>
            <el-button @click="del(s.row.id)" size="mini" type="danger">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑分类" :visible.sync="editBox" @close="editBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="分类名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item v-if="!(editForm.id > 0 && editForm.parent_id === 0)" label="父级分类">
            <el-select v-model="editForm.parent_id" placeholder="父级分类id">
              <el-option
                  v-for="item in selectOptList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "category",
  data() {
    return {
      list: [],
      editBox: false,
      editForm: {
        title: "",
        parent_id: 0,
      }
    }
  },
  mounted() {
    this.load();
  },
  computed:{
    selectOptList(){
      let opt = this.list.filter(e=>e.parent_id === 0);
      if (!(this.editForm.id > 0 && this.editForm.parent_id >0)){
        opt.unshift({id:0,title: "父级分类"})
      }
      return opt
    }
  },
  methods: {
    load() {
      this.$u.api.shop.goods.cateAll().then(res => {
        this.list = res;
      })
    },
    del(id){
      this.$u.api.shop.goods.cateDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    editBoxClose(){
      this.editForm = {
        title: "",
        parent_id: 0,
      }
    },
    edit(item){
      this.editForm = {...item}
      this.editBox = true;
    },
    editSubmit(){
      if(this.editForm.id){
        this.$u.api.shop.goods.cateEdit(this.editForm).then(()=>{
          this.editBox =false;
          this.$message.success("操作成功");
          this.load();
        })
        return
      }
      this.$u.api.shop.goods.cateAdd(this.editForm).then(()=>{
        this.editBox =false;
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>